import $ from 'jquery'
import 'core-js/es/number'
import Swiper, { Navigation, A11y, Autoplay } from 'swiper/swiper.esm'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, A11y, Autoplay])

class CarouselPosts extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$slider = $('[data-slider]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const { options } = this.props
    const config = {
      a11y: options.a11y,
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 10,
      freeMode: false,
      breakpoints: {
        767: {
          slidesPerView: 3,
          spaceBetween: 20,
          freeMode: false
        },
        1240: {
          slidesPerView: 3,
          spaceBetween: 60,
          freeMode: false
        }
      },
      navigation: {
        nextEl: this.$buttonNext.get(0),
        prevEl: this.$buttonPrev.get(0)
      },
      on: {
        beforeResize () {
          // if (window.innerWidth >= 767) {
          // console.log(window.innerWidth)
          $('.swiper-slide', this.swiper).css('width', '')
          // }
        }
      }
    }

    if (options.autoplay && options.autoplaySpeed) {
      config.autoplay = {
        delay: options.autoplaySpeed
      }
    }

    this.swiper = new Swiper(this.$slider.get(0), config)
  }
}

window.customElements.define('carousel-posts', CarouselPosts, { extends: 'div' })
