import $ from 'jquery'
import 'readmore-js'

class BlockProfiles extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)

    $('.post-copy').readmore({
      speed: 100,
      collapsedHeight: 185,
      moreLink: '<div class="read-container"><a href="#" class="read-link read-more">Read more</a></div>',
      lessLink: '<div class="read-container"><a href="#" class="read-link read-less">Read less</a></div>'
    })
  }
}

window.customElements.define('block-profiles', BlockProfiles, { extends: 'div' })
