import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

class NavigationBurger extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  bindFunctions () {
    this.triggerMenu = this.triggerMenu.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-toggle-menu]', this.triggerMenu)
  }

  resolveElements () {
    this.$menu = $('.menu', this)
    this.$menuButton = $('.hamburger', this)
  }

  connectedCallback () {
    // Mobile submenus hide/show
    $('[data-toggle-submenu]', this.$).each(function (i, obj) {
      // Current menu open by default
      if ($(obj).parent('li').hasClass('current-menu-ancestor') || $(obj).parent('li').hasClass('current-menu-item ')) {
        $(obj).addClass('is-open')
      }

      // All menu buttons level 0
      $(obj).on('click', function (e) {
        e.preventDefault()
        if ($(obj).next('.menu').is(':visible')) {
          $(obj).removeClass('is-open')
        } else {
          $(obj).addClass('is-open')
        }
        $(obj).next('.menu').slideToggle()
        // $(obj).next('.menu').toggleClass('is-active')
      })
    })
  }

  triggerMenu (e) {
    this.$.toggleClass('flyntComponent-menuIsOpen')
    this.$menuButton.attr('aria-expanded', this.$menuButton.attr('aria-expanded') === 'false' ? 'true' : 'false')
    if (this.$.hasClass('flyntComponent-menuIsOpen')) {
      disableBodyScroll(this.$menu.get(0))
    } else {
      enableBodyScroll(this.$menu.get(0))
    }
  }
}

window.customElements.define('navigation-burger', NavigationBurger, {
  extends: 'nav'
})
