import $ from 'jquery'

class BlockResources extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    // this.setOptions()
    // this.resolveElements()
  }

  // setOptions () {
  //   this.cookieName = 'cookieNoticeSeen'
  // }

  // resolveElements () {
  //   this.$closeButton = $('[data-close]', this)
  // }

  connectedCallback () {
    window.addEventListener('DOMContentLoaded', (event) => {
      $('[data-expand-toggle]', this).on('click', function (e) {
        const expandToggle = $(this).data('expandToggle')
        const expandParent = $("[data-expand-parent='" + expandToggle + "']")
        if ($(expandParent).hasClass('collapsed')) {
          $(expandParent).removeClass('collapsed')
          $(expandParent).attr('aria-expanded', 'true')
        } else {
          $(expandParent).addClass('collapsed')
          $(expandParent).attr('aria-expanded', 'false')
        }
      })
    })
  }
}

window.customElements.define('block-resources', BlockResources, { extends: 'div' })
